:root {
    --base-color: 33;
  
    --black: hsla(var(--base-color), 0%, 0%, 1);
    --white: hsla(var(--base-color), 0%, 100%, 1);
    --theme-black: hsla(var(--base-color), 2%, 30%, 1);
    --theme-white: hsla(var(--base-color), 100%, 99%, 1);
    --light-color: hsla(var(--base-color), 4%, 67%, 1);
    --light-grey-bg: hsla(var(--base-color), 0%, 96%, 1);
  
    --theme-color: hsla(var(--base-color), 100%, 55%, 1);
    --theme-color-dark: hsla(var(--base-color), 100%, 42%, 1);
    --light-theme-bg: hsla(var(--base-color), 100%, 99%, 1);
    --light-theme-bg1: hsla(var(--base-color), 100%, 92%, 1);
  
    --semibold-font: BalooTamma2-SemiBold;
    --regular-font: BalooTamma2-Regular;
    --medium-font: BalooTamma2-Medium;
  
    --inter-light-font: Inter-SemiBold;
    --inter-regular-font: Inter-Regular;
  }
  
  @font-face {
    font-family: Inter-SemiBold;
    src: url("../fonts/Inter-SemiBold.ttf");
  }
  @font-face {
    font-family: Inter-Regular;
    src: url("../fonts/Inter-Regular.ttf");
  }
  
  /*login register page*/
  .login-theme-color {
    color: var(--theme-color) !important;
  }
  
  /*content-area*/
  .filename {
    width: 350px; /* Adjust this if necessary */
  }
  .theme-border-btn {
    color: var(--theme-color) !important;
    background-color: var(--white);
    border-color: var(--theme-color);
  }
  .theme-border-btn:hover {
    color: var(--white) !important;
    background-color: var(--theme-color);
  }
  .white-btn-bg {
    background-color: var(--white) !important;
    border-color: var(--theme-color) !important;
    color: var(--theme-color) !important;
  }
  .ai-chat-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  .ai-chat-selections-row {
    display: flex;
    flex-wrap: wrap;
  }
  .ai-chat-msg-box {
    padding-right: 0px;
  }
  .ai-chat-msg-content {
    display: flex;
    flex-direction: column;
  }
  .ai-chat-msg-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }
  .ai-chat-msg-content::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  .ai-chat-msg-content::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
  }
  .ai-chat-msg-content::-webkit-scrollbar-thumb:hover {
    background-color: #ff981a;
  }
  .loading-threedots {
    font-size: 28px;
  }
  
  /*extra css*/
  .payment-btn {
    transition: all 0.2s ease;
    box-shadow: 0 8px 16px rgba(156, 149, 149, 0.1) !important;
  }
  .top-right-modal .modal-dialog {
    position: fixed;
    top: 2rem;
    right: 2rem;
    margin: 0;
    min-width: 400px;
    /* Match the width of the modal to your screenshot */
  }
  .webcam-circle {
    display: none;
    /* default state, will be overridden by JavaScript when recording starts */
    position: fixed;
    bottom: 90px;
    left: 100px;
    width: 240px;
    height: 240px;
    border: 2px solid #ff623e;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1001;
  }
  .webcam-video {
    height: 240px;
    width: 240px;
    object-fit: cover;
  }
  .video-controller {
    display: none;
    /* default state, will be overridden by JavaScript when recording starts */
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    left: 50px;
    width: 300px;
    background-color: #333;
    border-radius: 30px;
    padding: 10px;
    margin: 20px;
    border: 2px solid #fff;
    z-index: 1001;
  }
  .loading-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-modal-content {
    border-radius: 4px;
    padding: 10px;
    text-align: center;
  }
  .loading-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--theme-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    margin-top: 10px;
  }
  .loading-text {
    color: var(--theme-color);
    font-size: 24px;
    /* Remove the quotes */
    font-weight: bold;
    /* Remove the quotes */
  }
  .loading-modal2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: none;
    justify-content: center;
    align-items: center;
  }
  .loading-modal-content2 {
    /* background-color: white; */
    border-radius: 4px;
    text-align: center;
  }
  .loading-loader2 {
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--theme-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  .loading-text2 {
    color: var(--theme-color);
    font-size: 28px;
    font-weight: bold;
  }
  .modal-overlay2 {
    display: flex;
    /* Allows centering using justify-content and align-items */
    justify-content: center;
    /* Horizontally centers the modal content */
    align-items: center;
    /* Vertically centers the modal content */
    position: fixed;
    /* Fixed position to cover entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background */
    z-index: 1000;
    /* Make sure it's on top of other elements */
  }
  .play-speed {
    color: var(--theme-color);
    height: 51px;
    width: 51px;
    border-radius: 50%;
    border: 2px solid var(--theme-color);
    font-family: var(--medium-font);
    border-radius: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }
  .chat-message {
    padding: 5px 10px;
    word-wrap: break-word;
    font-size: 0.9rem;
    display: inline-flex;
    /* Or use inline-block, depending on your layout needs */
    max-width: 80%;
  }
  .user-message {
    background-color: #e7f3fe;
    color: #333;
    align-self: flex-end;
    margin-left: 15px;
    padding: 10px;
    border-radius: 10px 10px 0 10px;
    word-wrap: break-word;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bot-response {
    background-color: #f1f0f0;
    /* Light grey background */
    color: #333;
    align-self: flex-start;
    margin-right: 15px;
    padding: 10px;
    border-radius: 10px 10px 10px 0;
    word-wrap: break-word;
  }
  .bot-response p {
    color: #333;
  }
  .green-outline-btn {
    background-color: white;
    /* White background */
    color: #ff981a;
    /* Green text color */
    border: 1px solid #ff981a;
    /* Green border */
    margin-top: 5px;
    border-radius: 10px;
  }
  .type-file::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }
  .type-file::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  .type-file::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
  }
  .type-file::-webkit-scrollbar-thumb:hover {
    background-color: #ff981a;
  }
  .center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, cyan, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  .file-prompt-container {
    height: 700px;
  }
  .file-view {
    width: 80%;
    margin: 0 auto;
    font-size: 16px;
    overflow-y: hidden;
    padding-right: 10px;
    text-align: justify;
  }
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* This will create a responsive grid layout */
    align-items: start;
    /* Align items to the start of the grid area */
  }
  .feature-item {
    display: flex;
    flex-direction: column;
    /* Stack the image and paragraph vertically */
    align-items: center;
    /* Center items horizontally */
    text-align: center;
    /* Center the text */
    margin-bottom: 10px;
    /* Add some space below each item */
  }
  .feature-item img {
    margin-bottom: 5px;
    /* Space between the image and the paragraph */
  }
  .file-session-container {
    font-family: "Arial", sans-serif;
  }
  .file-session-tab-bar,
  .file-session-tab-bar-workspace {
    display: flex;
    justify-content: center;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    /* Adjust the max width as needed */
    overflow: hidden;
    padding: 6px;
    margin-bottom: 20px;
  }
  .file-session-tab {
    flex: 1;
    padding: 6px 16px;
    text-align: center;
    background: var(--light-theme-bg1);
    color: var(--theme-color);
    border: none;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 30px;
    font-size: large;
    margin: 0 5px;
  }
  .file-session-tab.active {
    background-color: var(--theme-color);
    color: #fff;
  }
  .file-view::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }
  .file-view::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  .file-view::-webkit-scrollbar-thumb {
    background-color: #ff981a;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
  }
  .file-view::-webkit-scrollbar-thumb:hover {
    background-color: #ff981a;
  }
  .line:hover {
    background-color: rgba(255, 152, 26, 0.1);
  }
  .selected {
    background-color: rgba(255, 152, 26, 0.3);
  }
  .manual-line {
    cursor: text;
  }
  .manual-line:hover {
    background-color: transparent;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0 10px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #ff981a;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #ff981a;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  .toggle-container {
    display: flex;
    align-items: center;
    /* Vertically centers the flex items */
    justify-content: center;
    /* Horizontally centers the flex items */
  }
  .no-interaction {
    pointer-events: none;
  }
  .ai-chat-size {
    width: 80%;
    margin: 0 auto;
    font-size: 16px;
    padding-right: 10px;
    text-align: justify;
  }
  .rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
  .ai-chat-prompts-box {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: scroll;
    margin: 0 auto;
    position: relative;
  }
  .prompts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    overflow-y: auto;
  }
  .prompt-item {
    flex: 0 1 calc(20% - 20px);
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border: 1px solid var(--theme-color);
    box-shadow: 0px 3px 6px #00000008 !important;
    border-radius: 12px;
  }
  .ai-chat-prompts-box ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }
  .ai-chat-prompts-box ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  .ai-chat-prompts-box ::-webkit-scrollbar-thumb {
    background-color: #ff981a;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
  }
  .ai-chat-prompts-box :-webkit-scrollbar-thumb:hover {
    background-color: #ff981a;
  }
  .folder-list {
    display: flex;
    overflow-x: auto;
    width: 100%;
    justify-content: flex-start;
    padding: 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
  }
  .folder-item,
  .selected-folder {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-color);
    background-color: white;
    border: 1px solid var(--theme-color);
    box-shadow: 0px 3px 6px #00000008 !important;
    border-radius: 12px;
    outline: 0 !important;
    transition: all ease 0.5s;
    height: 50px;
    margin-right: 10px;
  }
  .folder-item:last-child {
    margin-right: 0;
    /* Removes the right margin for the last item */
  }
  .selected-folder {
    background-color: var(--theme-color);
    color: white;
  }
  .line-loading-effect {
    width: 100%;
    height: 20px;
    /* Adjust the height as needed */
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  @keyframes loading {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  .line p {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
  }
  .line h1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    word-spacing: normal;
  }
  .line h2 {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.3;
    word-spacing: normal;
  }
  .line h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.4;
    word-spacing: normal;
  }
  .line li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    word-spacing: normal;
  }
  .line-container {
    position: relative;
    /* Parent container */
  }
  .line {
    display: flex;
    flex-direction: "row";
    position: relative;
    user-select: text;
  }
  .loading-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: large;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    word-spacing: 2px;
    line-height: 2;
  }
  .options-line {
    display: flex;
    margin: 10px;
  }
  .folder-item,
  .prompt-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Adjusts items to each end */
    width: 100%;
    /* Ensures it takes full width */
  }
  .title {
    flex-grow: 1;
    text-align: center;
  }
  .menu-icon {
    /* Ensures the icon is aligned to the right */
    cursor: pointer;
    /* Optional: Changes cursor on hover */
  }
  .icon-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  .disabled-link {
    pointer-events: none; /* Prevent clicking */
    cursor: not-allowed;
    opacity: 0.5;
  }
  .order-in-group {
    margin-right: 0px;
  }
  .chatBox::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }
  .chatBox::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
    margin-top: 6px;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .chatBox::-webkit-scrollbar-thumb {
    background-color: #ff981a;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
  }
  .chatBox::-webkit-scrollbar-thumb:hover {
    background-color: #ff981a;
  }
  .selectBox .dropdown-menu.select-top-dropdown-menu {
    top: auto;
    bottom: 100%;
  }
  .selectBox .dropdown-menu.select-small-dropdown-menu {
    max-height: 200px;
  }
  .input-range-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .editor-layout {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  
  /*editor pages design*/
  
  .blue-bg {
    background-color: #007aff;
  }
  .editor-page-full-width {
    width: 100%;
    display: flex;
    gap: 15px;
  }
  .editor-page-left-width {
    min-width: 480px;
    width: 480px;
  }
  .editor-page-right-width {
    width: 100%;
  }
  .text-info-left-card {
    height: calc(100vh - 60px);
    position: relative;
    overflow-y: auto;
  }
  .text-format-content-area {
    height: calc(100vh - 188px);
    overflow-y: auto;
    position: relative;
  }
  .border-template-list-div .dropdown.dropend .dropdown-menu {
    right: 0px !important;
    left: auto !important;
    top: 100% !important;
    transform: initial !important;
  }
  .text-format-empty-card-aread {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-content-area-before-upgrade {
    height: 50%;
    overflow: hidden;
  }
  .text-left-btn-padding {
    padding-left: 50px;
  }
  .text-theme-logo-icon {
    position: absolute;
    top: 30%;
    left: 0px;
    z-index: 10;
  }
  .btn {
    box-shadow: none !important;
    outline: 0 !important;
  }
  .text-info-vertical-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .text-content-area-width-shade {
    position: relative;
    display: block;
  }
  .text-content-area-width-shade::before {
    content: "";
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 9;
  }
  .text-content-area-font-style {
    position: relative;
    display: block;
    font-family: var(--inter-regular-font);
  }
  .text-content-area-font-style p {
    font-family: var(--inter-regular-font);
    color: var(--theme-black);
  }
  .text-content-area-font-style .text-semibold {
    font-family: var(--inter-light-font);
  }
  .text-content-area-font-style h1 {
    font-size: 30px;
    font-family: var(--medium-font);
    margin-bottom: 15px;
  }
  .text-content-area-font-style h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .text-content-area-font-style h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .border-template-list {
    padding: 10px 15px 10px 5px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: #fff;
  }
  .border-template-list p {
    margin-bottom: 0px;
    transition: all ease 0.5s;
  }
  .border-template-list:hover p {
    color: var(--theme-color) !important;
  }
  .text-format-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .text-format-flex-div-sec {
    display: flex;
    align-items: center;
  }
  .text-format-flex-div {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .text-format-flex {
    display: flex;
    align-items: center;
  }
  .text-format-flex-text {
    display: flex;
    align-items: center;
  }
  .text-format-flex-text span {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .white-bg {
    background-color: var(--white);
  }
  .text-format-flex-div .text-format-flex span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 3px;
    color: var(--light-color);
    height: 26px;
    width: 26px;
  }
  .text-format-flex-div .text-format-flex span:hover {
    color: var(--theme-color);
  }
  .text-format-flex-div .text-format-flex span.light-disable-icon,
  .text-format-flex-div .text-format-flex span.light-disable-icon:hover {
    color: #dedede !important;
  }
  .text-upload-yellow-border-box {
    border-radius: 10px;
    border: 1px solid #ff9819;
    padding: 25px 20px;
    text-align: center;
  }
  .text-content-bottom-selection {
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
  }
  .text-content-bottom-selection label {
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 15px;
  }
  .text-content-bottom-selection .form-control {
    display: inline-flex;
    min-width: 200px;
  }
  .step-list-style-none li {
    list-style: none;
  }
  .voice-tag-list-div {
    display: flex;
    flex-wrap: wrap;
  }
  .voice-tag-list {
    width: 32%;
    margin-right: 1.33%;
    border: 1px solid #dadada;
    border-radius: 8px;
    background: #fff;
    padding: 8px 10px 4px;
    margin-top: 10px;
    text-align: center;
  }
  .voice-tag-list.active {
    border-color: var(--theme-color);
    color: var(--theme-color);
  }
  .first-close {
    display: none;
  }
  .msform .stepbar {
    margin: 30px 0px 50px;
  }
  .msform fieldset:not(:first-of-type) {
    display: none;
  }
  .stepbar-btns {
    margin-top: 35px;
  }
  .msform .btn {
    max-width: 200px;
  }
  .steps {
    font-size: 25px;
    color: gray;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: right;
  }
  .fieldlabels {
    color: gray;
    text-align: left;
  }
  .stepbar {
    padding-left: 0px;
    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
  }
  .stepbar li {
    list-style-type: none;
    color: #666;
    text-transform: uppercase;
    font-size: 9px;
    width: 25%;
    float: left;
    position: relative;
    letter-spacing: 1px;
  }
  .stepbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 28px;
    height: 28px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: var(--light-color);
    padding: 5px 0px 0px;
    background: white;
    border: 1px solid #aeaca8;
    border-radius: 25px;
    margin: 0 auto;
    position: relative;
    z-index: 9;
  }
  .stepbar li:after {
    content: "";
    width: 100%;
    height: 8px;
    background: #ececec;
    position: absolute;
    left: -50%;
    top: 10px;
    z-index: 0;
  }
  .stepbar li:first-child:after {
    left: 0px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .stepbar li:last-child:after {
    width: 150%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .stepbar li.active:before,
  .stepbar li.active:after {
    background: var(--theme-color);
    border-color: var(--theme-color);
    color: white;
  }
  .stepbar li.current:before {
    background: white;
    border-color: var(--theme-color);
    color: var(--theme-color);
  }
  .theme-hover-link {
    transition: all ease 0.5s;
  }
  .theme-hover-link:hover {
    color: var(--theme-color);
  }
  .template-editor-result-border-box {
    border-radius: 10px;
    border: 1px solid #dadada;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .template-editor-result-border-box p {
    color: var(--theme-black);
  }
  .template-editor-star-li {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .result-icon-btn {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #dadada;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    min-width: 36px;
    width: 36px;
  }
  .result-red-btn-icon {
    border-color: #ef0c0c;
    color: #ef0c0c;
  }
  .result-theme-btn-icon {
    border-color: var(--theme-color);
    color: var(--theme-color);
  }
  .text-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .text-next-prev-icon {
    border-radius: 7px;
    background: #f3f3f3;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .editor-template-list {
    margin-bottom: 30px;
  }
  .editor-template-list-flex {
    padding: 10px 15px 10px 5px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .grey-dot-round {
    height: 11px;
    width: 11px;
    min-width: 11px;
    display: inline-block;
    background-color: #d9d9d9;
    margin-right: 15px;
    border-radius: 50%;
  }
  .scrolling-tab {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    cursor: grab;
    cursor: grab;
    -webkit-overflow-scrolling: touch; /* Ensures smooth scrolling on touch devices */
  }
  .scrolling-tab::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for a cleaner look */
  }
  .nav-pills.rounded-btn-pills {
    border-bottom: none;
  }
  .nav-pills.rounded-btn-pills .nav-link {
    background: var(--white);
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #dadada !important;
    padding: 9px 15px 3px;
    color: var(--theme-black);
    opacity: 1;
  }
  .nav-pills.rounded-btn-pills .nav-link.active,
  .nav-pills.rounded-btn-pills .show > .nav-link {
    color: var(--theme-color);
    border-color: var(--theme-color) !important;
  }
  .nav-pills.rounded-btn-pills.tag-pills .nav-link {
    border-radius: 8px;
    margin-right: 10px;
  }
  .selectBox.bottom-selectbox .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
  .template-tab-content-area-height {
    height: calc(100vh - 260px);
    overflow-y: auto;
  }
  .template-tab-content-area-height::-webkit-scrollbar,
  .text-format-content-area::-webkit-scrollbar,
  .text-info-left-card::-webkit-scrollbar,
  .step-last-card-box-height::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }
  .template-tab-content-area-height::-webkit-scrollbar-track,
  .text-format-content-area::-webkit-scrollbar-track,
  .text-info-left-card::-webkit-scrollbar-track,
  .step-last-card-box-height::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  .template-tab-content-area-height::-webkit-scrollbar-thumb,
  .text-format-content-area::-webkit-scrollbar-thumb,
  .text-info-left-card::-webkit-scrollbar-thumb,
  .step-last-card-box-height::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
  }
  .no-toggle-icon::after {
    display: none !important;
  }
  .editor-file-name {
    margin-top: -15px;
  }
  .editor-file-name .form-control {
    width: 100%;
    max-width: 300px;
    background-color: transparent;
    border-color: transparent;
    transition: all ease 0.5s;
  }
  .editor-file-name:hover .form-control,
  .editor-file-name .form-control:focus {
    background-color: #ffffff;
    border-color: #e1e1e1;
  }
  .drop-item-icon {
    display: inline-block;
    min-width: 25px;
    width: 25px;
    text-align: center;
  }
  .multilayer-dropdown .dropdown-menu {
    overflow: visible !important;
  }
  .multilayer-dropdown .dropdown-submenu {
    position: relative;
  }
  .multilayer-dropdown .submenu-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .multilayer-dropdown .dropdown-menu .submenu {
    left: 100% !important;
    top: 0px !important;
  }
  .form-control.card-title {
    font-size: 20px !important;
  }
  .voice-tag-list-info-card {
    display: none;
  }
  .drop-flag-icon img {
    height: 18px;
    width: 26px;
    border-radius: 3px;
    object-fit: cover;
    margin-right: 8px;
  }
  .dropdown-menu.large-width-dropdown-menu {
    min-width: 250px;
  }
  .dropdown-menu .form-control {
    padding: 8px 15px 6px;
  }
  .language-dropdown .dropdown-menu {
    min-width: 190px;
    max-height: 300px;
    overflow: auto;
  }
  .language-dropdown .dropdown-menu::-webkit-scrollbar {
    display: none;
  }
  .language-dropdown .dropdown-toggle::after {
    content: "\f107";
    font-family: FontAwesome;
    vertical-align: initial;
    border: none;
  }
  .editor-icon-hr {
    margin-top: 10px !important;
    margin-bottom: 1rem !important;
  }
  .editor-upgrade-card {
    width: 100%;
    max-width: 700px;
    margin: auto;
  }
  .step-last-card-box-height {
    height: auto;
    max-height: 400px;
    overflow-y: auto;
  }
  .nowrap {
    white-space: nowrap;
  }
  .star-rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .star-rating .star {
    cursor: pointer;
    color: #ccc;
    margin: 0px 5px;
    transition: color 0.2s;
  }
  .star-rating .star:hover,
  .star-rating .star:hover ~ .star {
    color: var(--theme-color);
  }
  .star-rating .star.selected-star,
  .star-rating .star.selected-star ~ .star {
    color: var(--theme-color);
  }
  
  /*--------------------------------------------------------------
  responsive css
  --------------------------------------------------------------*/
  @media only screen and (max-width: 1680px) {
    .grey-dot-round {
      height: 8px;
      width: 8px;
      min-width: 8px;
      margin-top: -2px;
    }
    .text-format-flex-div .text-format-flex span {
      padding: 1px;
      height: 22px;
      width: 22px;
    }
    .text-format-flex-text span {
      font-size: 12px;
    }
    .msform .stepbar {
      margin: 15px 0px 20px;
    }
    .stepbar-btns {
      margin-top: 15px;
    }
    .stepbar li:after {
      height: 6px;
    }
    .stepbar li:before {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 15px;
    }
    .voice-tag-list {
      padding: 6px 10px 3px;
      margin-top: 8px;
    }
    .round-color-icon {
      height: 30px;
      min-width: 30px;
      width: 30px;
    }
    .result-icon-btn {
      height: 30px;
      min-width: 30px;
      width: 30px;
      border-radius: 6px;
    }
    .result-icon-btn svg {
      width: 16px;
      height: auto;
    }
    .text-left-btn-padding {
      padding-left: 40px;
    }
    .editor-upgrade-card {
      max-width: 600px;
    }
    .editor-page-left-width {
      min-width: 380px;
      width: 380px;
    }
    .step-info-box-icon svg {
      width: 40px;
      height: auto;
    }
  }
  
  @media only screen and (min-device-width: 1200px) and (max-device-width: 1680px) {
    .text-info-left-card {
      height: calc(100vh - 40px);
    }
    .text-format-content-area {
      height: calc(100vh - 143px);
    }
    .template-tab-content-area-height {
      height: calc(100vh - 211px);
      overflow-y: auto;
    }
    .language-dropdown .dropdown-menu .dropdown-item,
    .language-dropdown .dropdown-toggle {
      font-size: 13px;
      line-height: 20px;
    }
    .drop-flag-icon img {
      height: 15px;
      width: 22px;
      border-radius: 2px;
    }
    .dropdown-menu.large-width-dropdown-menu {
      min-width: 210px;
    }
    .language-dropdown .dropdown-menu {
      min-width: 160px;
      max-height: 300px;
      overflow: auto;
    }
    .language-dropdown .dropdown-menu .dropdown-item {
      color: var(--theme-black);
      padding: 0.25rem 0.75rem;
    }
  }
  
  @media only screen and (max-width: 1400px) {
    .filename {
      width: 250px;
    }
    .text-format-flex-div {
      gap: 15px;
    }
    .text-content-area-font-style h1 {
      font-size: 27px;
    }
    .text-content-area-font-style h2 {
      font-size: 22px;
    }
    .text-content-area-font-style h3 {
      font-size: 20px;
    }
    .editor-page-left-width {
      min-width: 350px;
      width: 350px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .msform .stepbar {
      margin: 15px 0px 30px;
    }
    #sidebar-menu-open.mobile-view {
      display: block !important;
    }
    .text-format-flex-div {
      gap: 10px;
    }
    .text-format-content-area {
      height: calc(100vh - 221px);
    }
    .text-info-left-card {
      height: calc(100vh - 104px);
    }
    .template-tab-content-area-height {
      height: calc(100vh - 296px);
    }
  }
  @media only screen and (max-width: 991px) {
    .ai-chat-div {
      border-right: none;
    }
    .ai-chat-msg-content {
      overflow-y: auto;
      padding-right: 10px;
    }
    .text-info-left-card {
      height: calc(100vh - 100px);
    }
    .text-format-content-area {
      height: 500px;
    }
    .text-theme-logo-icon {
      top: 15%;
    }
    .template-tab-content-area-height {
      height: calc(100vh - 355px);
    }
    .text-format-flex-div {
      gap: 10px;
    }
    .editor-page-full-width {
      display: block;
    }
    .editor-page-left-width {
      min-width: 100%;
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .file-session-tab-bar {
      margin-bottom: -44px;
      z-index: 1;
      position: relative;
    }
    .chat-message {
      font-size: medium;
    }
  }
  @media (max-width: 992px) {
    .file-session-tab-bar,
    .file-session-tab-bar-workspace {
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    .text-format-icons {
      display: block;
    }
    .text-format-flex-div {
      flex-wrap: wrap;
      margin-bottom: 12px;
    }
    .text-content-area-font-style h1 {
      font-size: 23px;
    }
    .text-content-area-font-style h2 {
      font-size: 20px;
    }
    .text-content-area-font-style h3 {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 575px) {
    .text-content-bottom-selection {
      display: flex;
      margin-right: 0px;
      margin-bottom: 15px !important;
    }
    .text-content-bottom-selection label {
      width: 50%;
    }
    .template-tab-content-area-height {
      height: calc(100vh - 295px);
    }
    .voice-tag-list {
      width: 48%;
    }
  }
  