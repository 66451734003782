.ql-toolbar,
.ql-container {
  border: none !important;
  
}

.ql-editor {
  font-family: var(--regular-font);
  
}

/* If you have specific elements within your editor that need different weights */
.ql-editor .ql-bold {
  font-family: var(--medium-font);
  /* Medium font for bold text */
  
}

.ql-snow h1 {
  font-size: 24px !important;
  font-weight: bold;
  line-height: 1.2;
  word-spacing: normal;
}

.ql-snow h2 {
  font-size: 22px !important;
  font-weight: bold;
  line-height: 1.3;
  word-spacing: normal;
}

.ql-snow h3 {
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.4;
  word-spacing: normal;
}

.ql-snow h4 {
  font-size: 16px !important;
  font-weight: bold;
  line-height: 1.4;
  word-spacing: normal;
}


.ql-snow h5 {
  font-size: 14px !important;
  font-weight: bold;
  line-height: 1.4;
  word-spacing: normal;
}

.ql-snow p {
  font-size: 12px !important;
  font-weight: normal;
  line-height: 1.5;
  color: #333;
}

.ql-snow li {
  font-size: 12px !important;
  font-weight: normal;
  line-height: 1.5;
  word-spacing: normal;
}

.ql-snow ol {
  font-size: 12px !important;
  font-weight: normal;
  line-height: 1.5;
  word-spacing: normal;
}



.ql-snow.ql-toolbar button:hover {
  color: #FF981A !important;
}

.ql-snow.ql-toolbar .ql-active {
  color: #FF981A !important;
}


.ql-toolbar.ql-snow {
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #FF981A !important;
  font-family: inherit !important;
 
}
.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  display: none !important;
}

.stats {
  font-size: 16px;
}

.ql-container.ql-snow {
  height: auto;
}

.ql-editor {
  height: calc(100vh - 129px);
  overflow-y: auto;
}

.compare-editor .ql-editor {
  height: 460px;
  overflow-y: auto;
}

.ql-editor::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: block;
}

.ql-editor::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: pointer;

}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: #FF981A;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background-color: #FF981A;
}

.editor-main {
  flex: 1;
}

.circle-container {
  width: 44px;
  position: relative;
}

.circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #FF981A;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 50px;
  transform: translateX(-100%);
}

.popup {
  width: 120px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 10;
  padding: 10px;
  border-radius: 4px;
}


.enhanced-editor {
  position: relative;
}

.ql-toolbar.ql-snow {
  justify-content: center !important;
}

.editor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 10; /* ensure it's above the editor */
}

.custom-html  h1 {
  font-size: 24px !important;
  font-weight: bold;
  line-height: 1.2;
  word-spacing: normal;
}

.custom-html h2 {
  font-size: 22px !important;
  font-weight: bold;
  line-height: 1.3;
  word-spacing: normal;
}

.custom-html h3 {
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.4;
  word-spacing: normal;
}

.custom-html h4 {
  font-size: 16px !important;
  font-weight: bold;
  line-height: 1.4;
  word-spacing: normal;
}


.custom-html h5 {
  font-size: 14px !important;
  font-weight: bold;
  line-height: 1.4;
  word-spacing: normal;
}

.custom-html p {
  font-size: 12px !important;
  font-weight: normal;
  line-height: 1.5;
  color: #333;
}

.custom-html li {
  font-size: 12px !important;
  font-weight: normal;
  line-height: 1.5;
  word-spacing: normal;
}

.custom-html ol {
  font-size: 12px !important;
  font-weight: normal;
  line-height: 1.5;
  word-spacing: normal;
}

  .ql-container.ql-snow {
      width: calc(100vw - 951px);
  }


/* Adjust if sidebar-div-box and editor-page-left-width change width under different breakpoints */
@media only screen and (max-width: 1680px) {
  .ql-container.ql-snow {
      width: calc(100vw - 841px);
  }
}



@media only screen and (max-width: 1600px) {
  .ql-container.ql-snow {
    width: calc(100vw - 750px);
  }
}




@media only screen and (max-width: 1400px) {
  .ql-container.ql-snow {
    width: calc(100vw - 750px);
  }
}


@media only screen and (max-width: 1200px) {
  .ql-container.ql-snow {
    width: calc(100vw - 480px);
  }
}

@media only screen and (max-width: 990px) {
  .ql-container.ql-snow {
    width: calc(100vw - 100px);
  }
}



