@font-face { font-family: BalooTamma2-SemiBold; src: url('../fonts/BalooTamma2-SemiBold.ttf'); } 
@font-face { font-family: BalooTamma2-Regular; src: url('../fonts/BalooTamma2-Regular.ttf'); } 
@font-face { font-family: BalooTamma2-Medium; src: url('../fonts/BalooTamma2-Medium.ttf'); } 

html {
    position: relative;
    height: 100%;
    scroll-behavior: smooth;
}
body {
  font-family: var(--regular-font);
  width: 100%;
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
  background-color: var(--white);
  color: var(--theme-black);
  zoom: 1;
}

/* common css */
.modal-backdrop {
  zoom: 1;
}
.nopadding {
  padding: 0px !important;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
}
p {
  font-size: 16px;
  line-height: 26px;
  color: var(--light-color);
}
.ft-14 {
  font-size: 15px !important;
}
.ft-18 {
  font-size: 20px !important;
}
.theme-bg {
  background-color: var(--theme-color) !important;
}
.theme-color {
  color: var(--theme-color) !important;
}
.dark-color, .dark-color p {
  color: var(--theme-black) !important;
}
.light-color {
  color: var(--light-color) !important;
}
.white-color, .white-color p {
  color: var(--theme-white) !important;
}
.regular {
  font-family: var(--regular-font) !important;
}
.medium {
  font-family: var(--medium-font) !important;
}
.text-semibold{
  font-family: var(--semibold-font)!important;
}
.ft-18-title {
  font-size: 20px;
  color: var(--theme-black);
  font-family: var(--regular-font);
}
.light-theme-bg {
  background-color: var(--light-theme-bg1) !important;
}

/*login register page*/
.login-box-height {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box {
  padding: 20px;
  display: block;
  max-width: 520px;
  margin: auto;
  height: auto;
  max-height: 100vh;
  overflow: auto;
}
.login-btn-sec {
  padding: 0px 50px;
}
.login-box::-webkit-scrollbar {
  display: none;
}
.login-box form {
  width: 100%;
}
.sign-form {
  display: none;
}
.mini-title {
  font-size: 25px;
  line-height: 35px;
  font-family: var(--medium-font);
}

/* btn design */
.theme-btn {
  display: inline-block;
  color: var(--white) !important;
  padding: 7px 25px;
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  box-shadow: 0px 3px 6px #00000008 !important;
  border-radius: 8px;
  font-family: var(--medium-font);
  outline: 0 !important;
  transition: all ease 0.5s;
}
.theme-btn:hover {
  background-color: var(--theme-color-dark);
}
.btn-icon {
  margin-right: 10px;
  margin-top: -3px;
}
.white-border-btn {
  color: var(--theme-black) !important;
  background-color: var(--white);
  border-color: #f2f2f2;
}
.white-border-btn:hover {
  background-color: var(--light-grey-bg);
}
.red-border-btn {
  color: #ff3938 !important;
  background-color: var(--white);
  border-color: #ff3938;
}
.red-border-btn:hover {
  color: var(--white) !important;
  background-color: #ff3938;
}
.min-btn-width {
  min-width: 140px;
}

/* input design */
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  color: var(--theme-black);
  font-family: var(--medium-font);
  margin-bottom: 8px;
}
.form-control {
  color: #6b6c6d;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 7px 20px;
  color: var(--theme-black);
}
.form-select {
  background-color: #ffffff !important;
}
.form-control::placeholder {
  color: #babdc1;
}
.form-control:focus {
  box-shadow: none;
  outline: 0;
  border-color: var(--theme-color);
}
.form-control:disabled, .form-control:read-only {
  background-color: var(--light-theme-bg1);
  opacity: 1;
}
.hover-underline:hover {
  text-decoration: underline;
}
.input-icon-div {
  position: relative;
}
.input-icon-div .form-control {
  padding-left: 40px;
}
.input-icon-div .input-icon {
  position: absolute;
  left: 12px;
  bottom: 8px;
  color: var(--light-color);
}

/* check box radio design */
.custom.checkbox > label,
.custom.radio > label {
  position: relative;
  cursor: pointer;
  padding-left: 3px;
  min-width: 21px;
}
.custom input[type="checkbox"],
.custom input[type="radio"] {
  position: relative;
  /* margin-left: -29px; */
  margin-right: 12px;
  cursor: pointer;
}
.custom input[type="checkbox"]:after,
.custom input[type="radio"]:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 0px;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid var(--theme-black);
  cursor: pointer;
}
.custom input[type="checkbox"]:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 3px;
  top: 4px;
  z-index: 1;
  width: 9px;
  height: 5px;
  border: 1px solid var(--theme-color);
  border-top-style: none;
  border-right-style: none;
}
.custom input[type="checkbox"]:checked:after,
.custom input[type="radio"]:checked:after {
  border-color: var(--theme-color);
}
.custom input[type="checkbox"]:checked:before {
  transform: rotate(-45deg) scale(1, 1);
}
.custom input[type="checkbox"]:after {
  border-radius: 3px;
}
.custom input[type="radio"] {
  top: -2px;
}
.custom input[type="checkbox"]:after, .custom input[type="radio"]:after {
  top: -1px;
  left: -2px;
}
.custom input[type="radio"]:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 3px;
  left: 2px;
  z-index: 1;
  width: 10px;
  height: 10px;
  background: var(--theme-color);
  border-radius: 50%;
}
.custom input[type="radio"]:checked:before {
  transform: scale(1, 1);
}
.custom input[type="radio"]:after {
  border-radius: 50%;
}
.custom.close-checkbox input[type="checkbox"]:before {
  content: "\00d7";
  color: var(--theme-color);
  font-size: 18px;
  line-height: 13px;
  border: none!important;
}
.custom.close-checkbox input[type="checkbox"]:checked:before {
  transform: rotate(-1deg) scale(1, 1);
}

/* login page slider */
.hero-bg-img {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 10px;
  display: flex;
}
.carousel-indicators {
  margin-bottom: 2rem;
}
.carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  border: none;
}
.hero-content {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding-bottom: 3rem;
}
.hero-img {
  max-height: 65vh;
  width: auto;
}

/* login page btns */
.login-btn {
  background-color: #007aff !important;
  border-color: #007aff !important;
}
.login-btn:hover {
  background-color: #0068d6 !important;
}
.login-blue-color {
  color: #007aff !important;
}
.login-box .custom input[type="checkbox"]:before {
  border-color: #007aff;
}
.login-box .custom input[type="checkbox"]:checked:after,
.login-box .custom input[type="radio"]:checked:after {
  border-color: #007aff;
}
.login-box .custom input[type="radio"]:before {
  background: #007aff;
}


/*sidebar-div*/
.sidebar-div-box {
  width: 280px;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  box-shadow: none;
  background: var(--white);
  border-right: 1px solid #DADADA;
  overflow: hidden;
  z-index: 999;
}
.sidebar-header {
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
}
.sidebar-header a {
  cursor: pointer;
}
.sidebar-header img{
  max-height: 70px;
}
.sidebar-div {
  padding: 20px 25px;
  height: calc(100vh - 94px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-div::-webkit-scrollbar {
  width: 8px;
}
.sidebar-div::-webkit-scrollbar-track {
  background: #f3f4f6;
}
.sidebar-div::-webkit-scrollbar-thumb {
  background: #d1d5db;
}
.sidebar-list {
  background-color: transparent;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: var(--light-color);
  border-radius: 8px;
  position: relative;
  font-family: var(--medium-font);
  transition: all ease 0.5s;
  cursor: pointer;
}
.sidebar-list.active {
  background-color: #ffffff;
  box-shadow: none;
  border: 1px solid #DADADA;
  color: var(--theme-black);
}
.sidebar-list::before {
  content: "";
  opacity: 0;
  visibility: hidden;
  width: 7px;
  height: 36px;
  display: inline-block;
  background: var(--theme-color);
  box-shadow: 0px 3px 20px #00000005;
  border-radius: 0px 9px 9px 0px;
  transition: all ease 0.5s;
  position: absolute;
  left: -26px;
}
.sidebar-list.active::before {
  visibility: visible;
  opacity: 1;
}
.sidebar-list:hover {
  color: var(--theme-black);
}
.sidebar-list-icon {
  display: inline-block;
  min-width: 1.25rem;
  width: 1.25rem;
  height: auto;
  margin-right: 0.75rem;
  margin-top: -5px;
}
.sidebar-list-icon svg {
  width: 16px;
  height: auto;
}

/* sidebar upgrade box & upgrade popup */
.upgrade-sidebar-box {
  background-color: #ffffff;
  padding: 8px 15px 15px;
  margin-bottom: 15px;
  box-shadow: none;
  border: 1px solid #DADADA;
  border-radius: 8px;
}
.upgrade-profile {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  object-fit: cover;
}
.upgrade-sidebar-box .progress {
  height: 8px;
}
.upgrade-sidebar-box .top-info-button {
  position: absolute;
  top: 6px;
  right: 7px;
  cursor: pointer;
}
.upgrade-info-desc-box{
  width: 100%;
  max-width: 450px;
  height: auto;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 35px;
  left: 270px;
  z-index: 999;
}
.upgrade-show{
  opacity: 1;
  visibility: visible;
}

/* sidebar menu responsive design */
.disable-layer {
  position: relative;
  overflow: hidden;
}
.disable-layer::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.75;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 100;
}
.sidebar-menu-close {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--theme-black);
}
.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

/*main-div*/
.main-div {
  width: 100%;
  min-height: 100vh;
  padding-left: 280px;
  background-color: var(--white);
}

/*header div*/
.header-div {
  padding: 0px 50px;
  width: 100%;
  background-color: var(--white);
  position: sticky;
  top: 0px;
  z-index: 99;
}
.header-div-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-bottom: 1px solid #e3e3e3;
}
.search-input .form-control {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 3px 20px #00000003;
  border-radius: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 50px;
}
.search-input.input-icon-div .input-icon {
  left: 18px;
  bottom: 13px;
}
.header-btn {
  color: var(--theme-black) !important;
  background-color: var(--white);
  border: none;
  min-width: 160px;
  text-align: left;
  padding: 6px 35px 6px 10px;
  white-space: nowrap;
  box-shadow: none !important;
  border-radius: 30px;
  outline: 0 !important;
  transition: all ease 0.5s;
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  font-weight: bold;
  font-family: var(--regular-font);
}
.rounded-btn {
  border-radius: 30px;
}
.round-color-icon {
  height: 35px;
  min-width: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 8px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-grey-bg);
}
.header-minute-text {
  display: flex;
  align-items: center;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #e3e3e3;
  white-space: nowrap;
  font-family: var(--medium-font);
  cursor: pointer;
}

/* dropdown design */
.dropdown-toggle {
  padding: 0px;
  box-shadow: none !important;
  outline: 0 !important;
}
.no-toggle-icon::after {
  display: none;
}
.dropstart .no-toggle-icon::before {
  display: none;
}
.dropdown-menu {
  padding: 0.25rem 0rem;
  box-shadow: none;
  border: 1px solid #DADADA;
  border-radius: 8px;
  overflow: hidden;
}
.dropdown-item {
  color: var(--theme-black);
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown-item:focus, .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
  color: var(--theme-black);
  background-color: var(--light-grey-bg);
}
.selected-speaker {
  color: var(--theme-black);
  background-color: var(--light-grey-bg);
}
@media all {
  .header-div .dropdown-menu {
    top: 180% !important;
    right: 0px !important;
    left: auto !important;
    transform: none !important;
    transition: all ease 0.3s;
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  .header-div .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    top: 100% !important;
  }
}
.brand-drop-logo {
  height: 25px;
  width: auto;
}

/* responisve header */
.res-header-dash-sticky {
  padding: 5px 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e3e3e3;
  position: sticky;
  top: 63.83px;
  z-index: 98;
}

/* search modal */
.search-modal .btn-close {
  position: relative !important;
  top: auto;
  right: auto;
}
.search-modal .modal-dialog {
  width: 100%;
  margin: 0px;
  max-width: 100%;
}
.search-modal .modal-content {
  border-radius: 0px;
  border: none;
}
.search-modal .modal-body {
  padding: 10px;
}
.search-modal .modal-body .search-input {
  width: 100%;
}

/*content-area*/
.content-area {
  position: relative;
  padding: 30px 50px;
}

/* base card deign*/
.card-title {
  font-size: 20px;
  color: var(--theme-black);
  font-family: var(--medium-font);
  margin-bottom: 25px;
}
.card-margin {
  margin-bottom: 25px;
}
.card {
  display: block;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #DADADA;
  overflow: hidden;
}
.theme-border{
  border-color: var(--theme-color);
}
.card-hover {
  cursor: pointer;
  transition: all ease 0.5s;
}
.card-hover:hover {
  background-color: var(--light-theme-bg1);
  /*    box-shadow: 20px 20px 20px #1a242e14;*/
}
.card-100 {
  height: 100%;
}
.card-padding {
  padding: 20px;
}
.card-padding-small {
  padding: 15px;
}

/* table */
.table {
  border-color: #e3e3e3 !important;
  color: var(--light-color);
  vertical-align: middle;
}
.table thead tr{
  background-color: var(--light-theme-bg1);
  border-radius: 8px;
  border: none;
}
.table thead tr th, .table thead tr td{
  border: none;
  vertical-align: middle;
}
.table thead tr th:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.table thead tr th:last-child{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.table th {
  line-height: 29px;
  font-family: var(--semibold-font);
  font-weight: initial;
  color: var(--theme-black);
}
.table th, .table td {
  padding: 7px 10px 5px;
}
.table tr .small-td {
  width: 30px;
  padding: 7px 2px 5px 10px;
}
.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom: none;
}
.table tbody tr:hover{
  background-color: #FAFAFA;
}
.table-status-caption{
  display: block;
  padding: 5px 0px;
  white-space: nowrap;
}
.action-icon-list {
  display: inline-flex;
}
.icons-hover {
  min-width: 32px;
  width: 32px;
  height: 32px;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease;
  margin-left: 8px;
  color: var(--theme-black);
}
.icons-hover:hover, .icons-hover:focus {
  border-radius: 50%;
  background-color: #DEDEDE;
}
.table-action-list{
  display: inline-flex;
}
.table tbody tr .table-action-list,.table-checkbox .checkbox{
  opacity: 0;
  transition: all ease .2s;
}
.table tbody .tr-hover:hover .table-action-list, .table tbody .tr-hover:hover .table-checkbox .checkbox{
  opacity: 1!important;
}
.table tbody tr.tr-action-show .table-action-list{
  opacity: 1!important;
}
.row-checkbox, .header-checkbox{
  margin-right: 0px!important;
}
.table thead tr.thead-hidden{
  display: none;
}
.table-page-icon {
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: var(--light-theme-bg1);
  color: var(--theme-color);
}
.table-serach {
  width: 100%;
  max-width: 320px;
}
.max-w-100{
  max-width: 100%;
}

/* resposnive table action */
.action-drop-icon {
  min-width: 20px;
  width: 20px;
  height: auto;
  margin-right: 12px;
  text-align: center;
}

/* pagination */
.pagination-sm .page-link {
  padding: 0px;
  font-size: 0.875rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px !important;
  background-color: #f3f3f3 !important;
  border: none !important;
  margin-left: 5px !important;
  font-family: var(--regular-font);
  color: var(--light-color) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.page-item.active .page-link {
  background-color: var(--theme-color) !important;
  color: var(--theme-white) !important;
}

/* tooltip */
.tooltip-inner {
  background-color: var(--theme-black);
  color: var(--white);
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] > .tooltip-arrow::before {
  border-bottom-color: var(--theme-black);
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] > .tooltip-arrow::before {
  border-top-color: var(--theme-black);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] > .tooltip-arrow::before {
  border-left-color: var(--theme-black);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] > .tooltip-arrow::before {
  border-right-color: var(--theme-black);
}
.no-file-card {
  background-color: var(--light-theme-bg);
  width: 50%;
}

/* modal popup */
@media (min-width: 767px) {
  .modal-dialog {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-content {
  background: var(--white);
  border-radius: 8px;
  border: 1px solid #DADADA;
  overflow: visible;
  z-index: 99;
}
.modal-header{
  border-bottom: none;
  padding: 15px 25px 5px;
}
.modal-content-right-radius {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}
.modal-body, .modal-body-padding {
  padding: 25px;
}
.btn-close {
  opacity: 0.5;
  position: absolute;
  right: 20px;
  top: 20px;
  box-shadow: none !important;
  outline: 0 !important;
  z-index: 99;
}
.modal-header .btn-close {
  position: relative;
  right: 0px;
  top: -3px;
}

/* input design */
.round-input {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 3px 20px #00000003 !important;
  border-radius: 30px;
}
.border-input {
  border: 1px solid #e6e6e6;
  box-shadow: none !important;
}

/* accordion collpase */
.accordion-item {
  border: 1px solid #e3e3e3;
  position: relative;
  padding-left: 25px;
}
.accordion-button {
  font-family: var(--medium-font);
  color: var(--theme-black) !important;
  padding: 25px 0px;
  line-height: 24px;
  background-color: #fff !important;
  box-shadow: none !important;
  border: none;
  align-items: flex-start;
}
.accordion-flush .accordion-collapse .accordion-body {
  color: var(--theme-black);
  padding: 0px;
  padding-bottom: 15px;
}
.collapse-icon {
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 23px;
  color: var(--theme-color);
  z-index: 9;
  font-size: 20px;
  line-height: 24px;
}
.accordion-button::after {
  content: "\f107";
  font: normal normal normal 24px/1 FontAwesome;
  background-image: none !important;
  color: var(--theme-color);
  height: auto;
  width: auto;
}
.ac-without-icon .accordion-item{
  padding-left: 0px;
}
.right-collpase-arrow.accordion-button::after{
  transform: rotate(-90deg);
}
.right-collpase-arrow.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

/* progressbar */
.progress {
  width: 100%;
  height: 18px;
  border-radius: 50px;
  background-color: var(--light-theme-bg1);
}
.progress-bar {
  background-color: var(--theme-color);
}

/* tab design */
.nav-pills {
  border-bottom: 1px solid #e3e3e3;
}
.icon-tab.nav-pills .nav-link {
  height: 55px;
  min-width: 100px;
}
.nav-pills .nav-link {
  background: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: 3px solid transparent;
  padding: 0px 15px;
  color: var(--theme-black);
  opacity: 0.5;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--theme-black);
  background-color: transparent;
  border-bottom-color: var(--theme-color);
  opacity: 1;
}

/* switch with text */
.text-switch .btn-toggle {
  margin: 0 5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 14px;
  width: 51px;
  border-radius: 50px;
  color: #000;
  background: #efefef;
  border: none;
  font-weight: 600;
}
.text-switch .btn-toggle:focus,
.text-switch .btn-toggle.focus,
.text-switch .btn-toggle:focus.active,
.text-switch .btn-toggle.focus.active {
  outline: none;
  box-shadow: none;
}
.text-switch .btn-toggle:before,
.text-switch .btn-toggle:after {
  width: 5rem;
  font-family: var(--regular-font);
  color: var(--theme-black);
  text-transform: initial;
  letter-spacing: 1px;
  position: absolute;
  bottom: -5px;
  transition: opacity 0.5s;
}
.text-switch .btn-toggle:before {
  content: var(--before-content, "Monthly");
  text-align: right;
  padding-right: 10px;
  left: -5rem;
  width: fit-content;
}
.text-switch .btn-toggle:after {
  content: var(--after-content, "Annual");
  text-align: left;
  padding-left: 10px;
  right: -5rem;
  opacity: 0.5;
}
.text-switch .btn-toggle.active:before {
  opacity: 0.5;
}
.text-switch .btn-toggle.active:after {
  opacity: 1;
}
.text-switch .btn-toggle .handle {
  position: absolute;
  top: -9px;
  left: 0px;
  width: 32px;
  height: 32px;
  border-radius: 1.125rem;
  background: var(--theme-color);
  transition: left 0.5s;
}
.text-switch .btn-toggle.active .handle {
  left: 19px;
  transition: left 0.5s;
}

/* select input with dropdown */
.selectBox {
  position: relative;
  cursor: pointer;
}
.selectBox__value {
  background-color: #fff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: left;
}
.selectBox:after {
  position: absolute;
  right: 12px;
  bottom: -3px;
  color: var(--theme-black);
  transform: translateY(-50%) rotate(0deg);
  transition: all 0.2s ease-in-out;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.001' height='7.165' viewBox='0 0 14.001 8.165'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23212121;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M13.861,60.224l-.7-.7a.441.441,0,0,0-.645,0L7,65.036,1.487,59.522a.441.441,0,0,0-.645,0l-.7.7a.441.441,0,0,0,0,.645l6.537,6.538a.441.441,0,0,0,.645,0l6.538-6.538a.442.442,0,0,0,0-.645Z' transform='translate(0 -59.382)'/%3E%3C/svg%3E");
}
.selectBox .dropdown-menu {
  transition: all 0.5s ease-in-out;
  display: block;
  top: 100%;
  width: 100%;
  z-index: 10;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 0px;
  visibility: hidden;
  opacity: 0;
}
.selectBox.show:after {
  transform: translateY(-50%) rotate(180deg);
}
.selectBox.show .dropdown-menu {
  transition: all 0.3s ease-in-out;
  visibility: visible;
  opacity: 1;
  z-index: 10;
}
.selectBox.disable .selectBox__value {
  background-color: var(--light-theme-bg1) !important;
}
.selectBox.disable.show .dropdown-menu {
  visibility: hidden !important;
  opacity: 0 !important;
}
.selectBox.disable.show:after {
  transform: translateY(-50%) rotate(0deg) !important;
}

/* range slider */
.water-slider .range-slider {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.water-slider .range-slider input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}
.water-slider .range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.water-slider .range-slider input[type="range"]:focus {
  outline: none;
}
.water-slider .range-slider input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.water-slider .range-slider__range {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 5px 0px;
  z-index: 9;
  cursor: pointer;
}
.water-slider .range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--theme-color);
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.water-slider .range-slider__range::-webkit-slider-thumb:hover {
  background: var(--theme-color);
}
.water-slider .range-slider__range:active::-webkit-slider-thumb {
  background: var(--theme-color);
}
.water-slider .range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: var(--theme-color);
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.water-slider .range-slider__range::-moz-range-thumb:hover {
  background: var(--theme-color);
}
.water-slider .range-slider__range:active::-moz-range-thumb {
  background: var(--theme-color);
}
.range-slider__value {
  display: none;
}
.water-slider.text-slider .range-slider__range {
  height: 12px;
}
.water-slider.text-slider .range-slider__range::-webkit-slider-thumb {
  width: 30px;
  height: 30px;
}
.water-slider.text-slider .range-slider__range::-moz-range-thumb {
  width: 30px;
  height: 30px;
}

/* tab with btn deign */
.nav-pills.btn-pills {
  border-bottom: none;
}
.nav-pills.btn-pills .nav-link {
  background: var(--light-theme-bg);
  border: 1px solid var(--theme-color);
  border-radius: 50px;
  border-bottom: 1px solid;
  padding: 6px 25px;
  color: var(--theme-color);
  opacity: 1;
  margin-right: 10px;
  font-family: var(--regular-font);
}
.nav-pills.btn-pills .nav-link.active, .nav-pills.btn-pills .show > .nav-link {
  color: var(--theme-white);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  opacity: 1;
}
.btn-tab-icon img {
  height: 24px;
  width: auto;
}
.btn-active-icon {
  display: none;
}
.nav-pills.btn-pills .nav-link.active .btn-tab-icon img.btn-reg-icon {
  display: none;
}
.nav-pills.btn-pills .nav-link.active .btn-tab-icon img.btn-active-icon {
  display: inline-block;
}


/* now start css for specific design */

.img-icon-list {
  height: 85px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-icon-list span {
  margin: 10px 15px;
}
.img-icon-list img {
  max-height: 65px;
  width: auto;
}
.filename {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.team-profiles-div {
  display: inline-flex;
}
.team-profiles-img {
  height: 60px;
  min-width: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid var(--theme-color);
  margin-left: -30px;
  object-fit: cover;
  background-color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-profiles-div img:first-child {
  margin-left: 0px;
}
.pointer {
  cursor: pointer;
}
.img-list img {
  margin-right: 15px;
  margin-bottom: 15px;
}
.medium-title {
  font-size: 30px;
  line-height: 40px;
  font-family: var(--medium-font);
}
.filename-container {
  display: flex; /* Uses flexbox layout */
  align-items: center; /* Vertically centers children if they're of different heights */
}
.filename {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.small-title {
  font-size: 22px;
  line-height: 33px;
  color: var(--theme-black);
  font-family: var(--regular-font);
  display: flex;
  align-items: center; /* Vertically center content */
}
.small-title span, .small-title a {
  white-space: nowrap; /* Prevents the content from wrapping to the next line */
}
.small-title .filename {
  flex: 1; /* This will make the filename take the maximum available space */
  overflow: hidden; /* Hide excess content */
  text-overflow: ellipsis; /* Add ... for overflow content */
  margin: 0 10px; /* Add some space around filename */
  width: initial;
  max-width: 250px;
}
.border-dashed-box {
  border: 1px dashed #d9d9d9;
  border-radius: 22px;
  padding: 15px;
  height: 100%;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sec-pad-top {
  padding-top: 40px;
}
.record-time-div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 24px;
  font-family: var(--medium-font);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 3px solid #f9fcff;
}
.transparent-white-btn {
  color: var(--white) !important;
  background-color: var(--theme-color);
  border: 2px solid var(--white);
  box-shadow: 0px 3px 20px #00000003;
  border-radius: 15px;
}
.transparent-white-btn:hover {
  background-color: var(--white);
  color: var(--theme-color) !important;
}
.dark-p p {
  color: var(--theme-black);
  font-family: var(--regular-font);
}
ol,
ul {
  margin-bottom: 0px;
  padding-left: 1rem;
}
.drag-upload-div {
  background-color: #ffffff;
  border: 1px dashed var(--theme-color);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  min-height: 110px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
.underline {
  text-decoration: underline !important;
}
.img-shadow-box {
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
}
.logout-icon-div {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: inline-flex;
  align-content: center;
  justify-content: center;
}
.logout-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #ff3938;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  transition: all ease 0.5s;
}
.logout-box-card:hover .logout-icon {
  height: 70px;
  width: 70px;
}
.account-profile {
  height: 80px;
  width: 80px;
  min-width: 80px;;
  border-radius: 50%;
  border: 3px solid var(--theme-color);
  object-fit: cover;
  margin-bottom: 8px;
}
hr {
  background-color: #e3e3e3;
  opacity: 1;
}
.profile-list-div-width{
  max-width: 70%;
}
.profile-list {
  display: flex;
  margin-bottom: 15px;
}
.profile-list p {
  margin-bottom: 0px;
}
.profile-list-title {
  min-width: 100px;
  padding-right: 10px;
}
.profile-list-desc-div {
  display: flex;
  width: calc(100% - 100px);
}
.profile-list-desc {
  color: var(--theme-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.desc-copy-icon {
  min-width: 16px;
}
.profile-card-box {
  position: relative;
  cursor: pointer;
  transition: all ease .5s;
}
.profile-card-box:hover p{
  transition: all ease 0.5s;
}
.profile-card-box:hover{
  border-color: var(--theme-color);
}
.profile-card-box:hover, .profile-card-box:hover p {
  color: var(--theme-color)!important;
}
.profile-red-box-card{
  transition: all ease .5s;
}
.profile-red-box-card:hover{
  border-color: #DF0909;
  color: #DF0909;
}
.text-danger {
  color: #ff3938 !important;
}
.edit-profile-div {
  display: inline-block;
  position: relative;
}
.edit-profile-icon {
  position: absolute;
  bottom: 4px;
  right: -2px;
  cursor: pointer;
  height: 35px;
  min-width: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-white);
  background-color: var(--theme-color);
}
.round-theme-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: var(--light-theme-bg1);
  border: 1px solid var(--theme-color);
}
.small-table-btn {
  display: inline-block;
  color: var(--white) !important;
  padding: 8px 20px;
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  box-shadow: 0px 3px 6px #00000003 !important;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  outline: 0 !important;
}
.green-btn-bg {
  background-color: #00d13f !important;
  border-color: #00d13f !important;
}
.purple-btn-bg {
  background-color: #6a6cff !important;
  border-color: #6a6cff !important;
}
.red-btn-bg {
  background-color: #ff3938 !important;
  border-color: #ff3938 !important;
}
.blue-logo-text {
  color: #007aff;
}
.pink-logo-text {
  color: #a60298;
}
.yellow-logo-text {
  color: #ff9819;
}
.green-logo-text {
  color: #049426;
}
.amigotor-color {
  color: #FF981A;
}
.red-logo-text {
  color: #ff5757;
}
.offer-div {
  background-color: #00d13f;
  border-radius: 7px;
  padding: 4px 7px;
  color: var(--theme-white);
  font-family: var(--regular-font);
  font-size: 15px;
  line-height: 18px;
}
.package-price {
  font-size: 65px;
  line-height: 70px;
  font-family: var(--medium-font);
}
.package-input {
  width: 100%;
  max-width: 300px;
  margin: auto;
}
.counter-input {
  width: 100%;
  margin: auto;
  max-width: 210px;
  position: relative;
}
.counter-input .form-control {
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
}
.count {
  font-size: 20px;
  font-family: var(--regular-font);
  min-width: 44px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
}
.count.c-plus {
  right: 0px;
}
.count.c-minus {
  left: 0px;
}
.feature-box {
  text-align: center;
}
.feature-box p {
  font-family: var(--regular-font);
  color: var(--theme-black);
  margin-bottom: 0px;
}
.feature-text-pad {
  padding: 8px 30px;
}
.feature-text-div {
  padding: 0px 10px;
  text-align: left;
}
.f-border {
  border-right: 1px solid #e0e8f1;
}
.f-tag {
  display: inline-block;
  background-color: var(--light-theme-bg1);
  color: var(--theme-color) !important;
  padding: 5px 20px;
  border-radius: 10px;
  min-width: 110px;
  text-align: center;
}
.light-red-bg {
  background-color: #ffebeb;
  color: #ff3938 !important;
}
.moretext {
  display: none;
}
.choose-list {
  padding: 8px 15px;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;
}
.choose-list.active {
  background-color: var(--light-theme-bg1);
  color: var(--theme-color);
}
.choose-list span svg {
  margin-top: -3px;
}
.vertical-flex-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.successfully-shared {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  color: var(--theme-color);
  border-radius: 8px;
  background-color: var(--light-theme-bg1);
  padding: 0.75em 1em;
  width: 100%;
  max-width: 350px;
  font-family: var(--regular-font);
  font-size: 17px;
}
.successfully-shared.show {
  display: block;
}
.input-group .btn {
  z-index: 0;
}
.min-100vh-relative-div {
  min-height: calc(100vh - 119px);
  position: relative;
  padding-bottom: 170px;
}
.audio-play-div {
  position: fixed;
  z-index: 99;
  bottom: 0px;
  left: 330px;
  right: 0px;
  width: calc(100% - 380px);
  background-color: var(--light-theme-bg);
  box-shadow: -20px -20px 20px #04274d0d;
  border-radius: 36px 36px 0px 0px;
  padding: 35px 140px 12px;
}
.audio-details-div {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio-play-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 25px;
}
.audio-play-icons img,
.audio-play-icons svg {
  max-height: 23px;
  width: auto;
}
.play-theme-round {
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  margin: 0px 25px;
}
.play-speed {
  color: var(--theme-color);
  height: 51px;
  width: 51px;
  border-radius: 50%;
  border: 2px solid var(--theme-color);
  font-family: var(--medium-font);
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.play-time {
  color: var(--theme-color);
  font-size: 15px;
  border-radius: 50px;
  border: 2px solid var(--theme-color);
  font-family: var(--medium-font);
  border-radius: 27px;
  padding: 8px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}
.slider-theme-icon {
  min-width: 70px;
  width: 70px;
  height: 70px;
  padding: 10px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #d4e8ff;
  color: #088aff;
}
.slider-theme-icon-small {
  min-width: 50px;
  width: 50px;
  height: 50px;
}
.slider-p {
  font-family: var(--regular-font);
  color: var(--theme-black);
  margin-bottom: 0px;
}
.slider-hover-card {
  position: relative;
  cursor: pointer;
  margin: 0px 12px;
  box-shadow: 8px 8px 8px #1a242e08;
}
.slider-hover-box {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #088aff;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
  padding: 20px;
}
.slider-hover-box p {
  color: #fff;
  margin-bottom: 0px;
}
.slider-hover-card:hover .slider-hover-box {
  opacity: 1;
}
.yellow-theme-slider .slider-theme-icon {
  background-color: #ffecd4;
  color: #ff9819;
}
.yellow-theme-slider .slider-hover-box {
  background-color: #ff9819;
}
.pink-theme-slider .slider-theme-icon {
  background-color: #ffdffc;
  color: #a60298;
}
.pink-theme-slider .slider-hover-box {
  background-color: #a60298;
}
.file-slider .carousel-indicators {
  position: relative;
  margin-top: 10px;
}
.file-slider .carousel-indicators [data-bs-target] {
  background-color: var(--theme-color);
  opacity: 0.2;
}
.file-slider .carousel-indicators .active {
  opacity: 1;
}
.file-action-icon-flex {
  display: inline-flex;
  flex-wrap: wrap;
}
.file-action-list {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}
.file-action-list img, .file-action-list svg {
  width: 100%;
  max-width: 20px;
  height: auto;
}
.info-icon {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-theme-bg1);
  color: var(--theme-color);
  margin-right: 8px;
  margin-top: 2px;
}
.credit-round-btn {
  background-color: var(--light-theme-bg);
  border: 1px solid var(--theme-color);
  line-height: 20px !important;
}
.credit-round-btn .ft-18 {
  line-height: 20px !important;
}
.credit-round-btn .round-color-icon {
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.files-tab-div-height {
  width: 100%;
  /*height: calc(100vh - 380px);*/
  height: calc(100vh) !important;
  overflow-y: scroll;
}
.files-tab-div-height::-webkit-scrollbar, .ai-chat-div-height::-webkit-scrollbar, .transkript-list-res-height::-webkit-scrollbar {
  width: 10px;
}
.files-tab-div-height::-webkit-scrollbar-track, .ai-chat-div-height::-webkit-scrollbar-track, .transkript-list-res-height::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 50px;
}
.files-tab-div-height::-webkit-scrollbar-thumb, .ai-chat-div-height::-webkit-scrollbar-thumb, .transkript-list-res-height::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 50px;
}
.files-tab-div-height .nav-pills.btn-pills {
  background-color: #fff;
  position: sticky;
  top: 0px;
  z-index: 9;
}
.ai-chat-div-height {
  overflow-y: auto;
}
.ai-chat-div {
  border-right: 1px solid #e3e3e3;
}
.ai-chat-msg-box {
  display: flex;
  padding-right: 30px;
}
.ai-chat-msg-content {
  height: 100%;
  overflow-y: auto;
  padding-right: 30px;
  margin-bottom: 15px;
}
.ai-chat-msg-box .theme-btn img {
  min-width: 20px;
  width: 20px;
}
.ai-chat-msg-list {
  display: flex;
  margin-top: 10px;
}
.ai-chat-msg-list p {
  margin-bottom: 10px;
}
.transkript-list {
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 2px 15px;
  transition: all ease.5s;
}
.transkript-list:hover, .transkript-list.active {
  background-color: #e4e4e4;
}
.transcript-dropdown {
  min-width: 130px;
  width: 130px;
  margin-right: 10px;
}
.transcript-dropdown .dropdown-toggle {
  min-width: 130px;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  border-radius: 11px;
  color: var(--theme-color);
  padding: 6px 15px;
}
.transcript-dropdown .dropdown-menu {
  min-width: 250px;
}
.transcript-time {
  min-width: 110px;
  padding: 0px 15px;
  color: var(--light-color);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
}
.transcript-time-icon svg {
  margin-top: -2px;
  margin-right: 6px;
}
.transcript-content {
  color: var(--theme-black);
  font-family: var(--regular-font);
  width: 80%;
}
.transcript-content:focus {
  outline: none;
}
.shortcodes-p p {
  margin-bottom: 8px;
}
.filename::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit */
}
.status {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}
.spinner {
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.processing {
  color: #999; /* Light color for processing */
}
.failed {
  color: #ff4d4d; /* Red color for failed */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.chat-list-box {
  font-size: 15px;
  line-height: 20px;
  background-color: #f4f4f5;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}
.chat-list-box p {
  color: #000;
}
.semi-bold {
  font-weight: 600;
}
.loading-threedots {
  font-size: 30px;
}
.loading-threedots span {
  opacity: 0;
  animation: blink 1.4s infinite;
}
.loading-threedots span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-threedots span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.files-tab-div-height::-webkit-scrollbar {
  display: block;
  overflow: auto;
}
.dropdown-menu::-webkit-scrollbar {
  display: block;
  overflow: auto;
  width: 10px;
  background-color: #ffff;
}
.dropdown-menu::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 50px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 50px;
}
.highlighted {
  background-color: var(--light-theme-bg1);
}
.payment-bold {
  font-weight: 600;
  color: var(--base-color);
}
.time-speaker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-view {
  display: none;
}
.burger-mobile-view {
  display: none;
}
.burger-web-view {
  display: block;
}
.aichat-enablecard {
  width: 50%;
}
.recorder-span {
  width: 52px;
  height: 48px;
}
.recorder-span2 {
  width: 46px;
  height: 38px;
}
.recorder-img {
  width: 26px;
  height: 24px;
}
.subtype-span {
  font-size: small;
}
.payment-row {
  cursor: pointer;
}
.payment-card {
  cursor: pointer;
  transition: all 0.3s; /* Smooth transition for the effects */
}
.payment-card:hover {
  /* Example: Slight scale for a pop effect */
  transform: scale(1.03);

  /* Example: Add a shadow for depth */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/*.scale75 {
    transform: scale(0.75);
    transform-origin: top left;
  }
  */
.hover-effect {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.hover-effect:hover {
  transform: translateY(-5px);
}
.hover-span {
  visibility: hidden;
  background-color: #ffffff;
  color: var(--light-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 20;
  bottom: 105%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -30px; /* Use half of the width value to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 20px;
  white-space: nowrap; /* Prevent content from wrapping to the next line */
}
.hover-effect:hover .hover-span {
  visibility: visible;
  opacity: 1;
}
.google-div{
    max-width: 200px;
    overflow: hidden;
}
.info-terms{
    font-size: 12px;
    color: #aaaaaa;
}
.info-terms a{  
    color: #0d6efd;
}
.collpase-folder-list p{
  color: var(--theme-black);
  margin-bottom: 0px;
  cursor: pointer;
  padding: 4px 0px;
}
.table td .icons-hover {
  margin: 0px 4px;
}
.table th .icons-hover {
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 4px;
  margin: 0px 8px;
}
.table th.action-th{
  line-height: 21px;
}

/*--------------------------------------------------------------
  common responsive css
--------------------------------------------------------------*/

@media only screen and (max-width: 1600px) {
  .login-box{
    max-width: 450px;
  }
  .login-btn-sec{
    padding: 0px 30px;
  }
  .header-div{
    padding: 0px 20px;
  }
  .content-area{
    position: relative;
    padding: 20px;
  }
  .img-icon-list {
    margin-bottom: 8px;
  }
  .img-icon-list span {
    margin: 5px 10px;
  }
  .card-padding {
    padding: 15px;
  }
  .header-btn{
    min-width: initial;
    padding: 6px 20px 6px 6px;
  }
  .accordion-button {
    padding: 20px 0px;
  }
  .collapse-icon {
    top: 20px;
  }
  .accordion-flush .accordion-collapse .accordion-body{
    padding-bottom: 8px;
  }
  .package-price {
    font-size: 60px;
    line-height: 65px;
  }
  .file-action-list{
    height: 45px;
    width: 45px;
    margin-right: 3px;
  }
  .file-action-list img, .file-action-list svg{
    width: 100%;
    max-width: 20px;
    height: auto;
  }
  .min-100vh-relative-div {
    padding-bottom: 140px;
  }
  .files-tab-div-height{
    /*height: calc(100vh - 330px);*/
    height: calc(100vh - 70px);
  }
  .audio-play-div {
    left: 300px;
    width: calc(100% - 320px);
    padding: 25px 50px 12px;
  }
  .play-time {
    padding: 4px 25px;
  }
  .play-theme-round {
    height: 55px;
    width: 55px;
    min-width: 55px;
  }
  .play-speed {
    height: 45px;
    width: 45px;
    font-size: 13px;
  }
  .action-icon-list .icons-hover {
    min-width: 32px;
    width: 32px;
    height: 32px;
    padding: 8px;
  }
  .theme-btn .btn-icon svg{
    width: 14px;
    height: auto;
  }
  .small-table-btn .btn-icon img{
    max-width: 18px;
    height: auto;
  }
  .sidebox-upgrade-icon{
    max-width: 65px;
    height: auto;
  }
  .sidebox-create-icon{
    max-width: 80px;
    height: auto;
  }
  .action-drop-icon {
    min-width: 15px;
    width: 15px;
  }
  .card-margin {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1600px) {
  body, p, button, .btn, .form-control, label, .accordion-button, .selectBox .dropdown-menu .dropdown-item {
    font-size: 15px;
    line-height: 24px;
  }
  .ft-18, .ft-18-title{
    font-size: 16px;
    line-height: 26px;
  }
  .small-title{
    font-size: 20px;
    line-height: 30px;
  }
  .profile-list-title {
    min-width: 90px;
    padding-right: 5px;
  }
  .files-tab-div-height{
    /*height: calc(100vh - 380px);*/
    height: calc(100vh - 20px);
  }
}

@media only screen and (max-width: 1400px) {
  .form-control {
    padding: 7px 12px;
  }
  .theme-btn {
    padding: 7px 20px;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1680px){
  body, p, button, .btn, .form-control, label, .accordion-button, .selectBox .dropdown-menu .dropdown-item, .dropdown-menu .dropdown-item {
      font-size: 14px;
      line-height: 20px;
  }
  .ft-18, .ft-18-title {
      font-size: 16px;
      line-height: 24px;
  }
  .sidebar-div-box {
      width: 230px;
  }
  .main-div {
      padding-left: 230px;
  }
  .upgrade-info-desc-box {
      max-width: 350px;
      bottom: 25px;
      left: 215px;
  }
  .sidebar-header {
      height: 68px;
  }
  .sidebar-header img{
      max-height: 51px;
  }
  .sidebar-div {
      height: calc(100vh - 68px);
      padding: 0px 23px 10px;
  }
  .sidebar-list::before {
      left: -24px;
  }
  .sidebar-list {
      padding: 10px 15px 8px;
      margin-bottom: 8px;
  }
  .sidebar-list-icon {
      margin-top: -3px;
  }
  .upgrade-sidebar-box {
      padding: 8px 15px 8px;
  }
  .upgrade-profile {
      height: 45px;
      width: 45px;
  }
  .theme-btn {
      padding: 7px 20px;
  }
  .min-btn-width {
      min-width: 115px;
  }
  .content-area {
      padding: 20px 15px;
  }
  .card-padding {
      padding: 12px;
  }    
  .form-group {
      margin-bottom: 15px;
  }
  .dropdown-menu .form-control {
      padding: 6px 8px 4px;
  }
  .dropdown-menu .dropdown-item {
      padding: 0.3rem .75rem;
  }
  .drop-item-icon {
      min-width: 20px;
      width: 20px;
  }
  .drop-item-icon svg{
      width: 14px;
      height: auto;
  }
  .submenu-link.dropdown-item .sub-drop-icon svg{
      width: 8px;
      height: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .sidebar-div-box{
    width: 300px;
    left: -350px;
    transition: all ease .25s;
    overflow: initial;
  }
  .burger-mobile-view{
    display: block !important;
  }
  .burger-web-view{
    display: none !important;
  }
  .sidebar-div-box.show-sidebar{
    left: 0px;
  }
  .sidebar-header {
    height: 70px;
  }
  .sidebar-header img{
    max-height: 61px;
  }
  .sidebar-div {
    padding: 5px 25px 20px;
    height: calc(100vh - 70px);
  }
  .sidebar-list {
    padding: 11px 15px;
    margin-bottom: 5px;
  }
  .main-div {
    padding-left: 0px;
  }
  .header-btn{
    padding: 4px 4px;
  }
  .header-btn .round-color-icon {
    margin-right: 0px;
  }
  .res-header-side-icon{
    width: 40px;
    height: auto;
  }
  .header-logo{
    max-width: 130px;
    margin-left: 5px;
    height: auto;
  }
  .header-div{
    border-bottom: 1px solid #E3E3E3;
    height: 63.83px;
  }
  .header-div-flex {
    padding: 10px 0px;
    border-bottom: none;
  }
  .min-100vh-relative-div {
    padding-bottom: 120px;
  }
  .files-tab-div-height {
    /*height: calc(100vh - 320px);*/
    height: 100vh;
  }
  .audio-details-div {
    margin-top: 15px;
  }
  .audio-play-div {
    left: 20px;
    width: calc(100% - 40px);
    padding: 20px 50px 8px;
  }
  .play-time {
    padding: 3px 20px;
    font-size: 15px;
  }
  .play-theme-round {
    height: 50px;
    width: 50px;
    min-width: 50px;
  }
  .audio-play-icons img, .audio-play-icons svg {
    max-height: 20px;
  }
  .play-speed {
    height: 40px;
    width: 40px;
    font-size: 12px;
  }
  .credit-round-btn {
    padding: 6px 20px 6px 6px!important;
  }
  .credit-round-btn .round-color-icon {
    margin-right: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .hero-bg-img {
    height: auto;
  }
  .hero-img{
    max-height: initial;
  }
  .profile-card-hover-div{
    display: none;
  }
  .f-border {
    border-right: none;
  }
  .feature-text-pad {
    padding: 8px 5px;
  }
  .f-tag {
    font-size: 12px!important;
    line-height: 24px!important;
    padding: 3px 8px;
    border-radius: 6px;
    min-width: initial;
    width: 100%;
    max-width: 120px;
  }
  .feature-box {
    text-align: center;
    padding: 0px 12px;
  }
  .res-hide-td{
    display: none;
  }
  .res-action-list{
    display: none;
  }
  .res-action-dropdown{
    display: block;
  }
  .ai-chat-msg-box {
    display: flex;
    padding-right: 10px;
  }
  .files-tab-div-height {
    /*height: auto;*/
    height: calc(100vh - 80px);
  }
  .transkript-list-res-height{
    max-height: calc(100vh);
    overflow: auto;
  }
  .web-view{
    display: none !important;
  }
  .mobile-view{
    display: block !important;
  }
  .filename {
    max-width: 200px;
  }
  .aichat-enablecard{
    width: 100%;
  }
  .table thead tr th:first-child{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .table thead tr th:last-child{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .table tbody tr .table-action-list,.table-checkbox .checkbox{
    opacity: 1!important;
  }
  .quick-action-list{
    display: none;
  }
  .tooltip {
    opacity: 0!important;
  }
}
@media (max-width: 768px) {
  .time-speaker-container {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  .mini-title {
    font-size: 22px;
    line-height: 32px;
  }
  .medium-title {
    font-size: 23px;
    line-height: 25px;
  }
  .small-title{
    font-size: 20px;
    line-height: 28px;
  }
  .sec-pad-top{
    padding-top: 30px;
  }
  .text-switch .btn-toggle {
    margin: 0 4.5rem;
    width: 45px;
  }
  .text-switch .btn-toggle .handle {
    top: -7px;
    width: 26px;
    height: 26px;
  }
  .text-switch .btn-toggle.active .handle {
    left: 19px;
  }
  .package-price {
    font-size: 52px;
    line-height: 60px;
  }
  .transkript-list {
    border-radius: 4px;
    display: block;
    padding: 6px 5px;
  }
  .transcript-dropdown, .transcript-dropdown .dropdown-toggle {
    width: 95px;
    display: inline-block; 
  }
  .transcript-dropdown .dropdown-toggle {
    font-size: 12px;
    line-height: 20px;
    border-radius: 6px;
    padding: 3px 8px;
  }
  .transcript-time {
    min-width: 80px;
    padding: 0px 8px;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }
  .transcript-content {
    font-size: 12px;
    line-height: 20px;
    margin-top: 3px;
  }
  .profile-list-div-width{
    max-width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .content-area {
    padding: 20px;
  }
  .card-padding {
    padding: 10px;
  }
  .border-dashed-box{
    padding: 10px;
  }
  .theme-btn {
    padding: 7px 22px;
  }
  .btn-icon {
    margin-right: 5px;
  }
  .header-btn {
    margin-right: 5px;
  }
  .header-minute-text {
    padding-right: 10px;
    margin-right: 10px;
    white-space: initial;
  }
  .accordion-item {
    padding-left: 20px;
  }
  .modal-body, .modal-body-padding {
    padding: 30px 15px;
  }
  .btn-close {
    right: 12px;
    top: 12px;
  }
  .icon-tab.nav-pills .nav-link{
    height: 55px;
    min-width: initial;
  }
  .table-serach{
    width: 100%;
    max-width: 250px;
  }
  .ai-chat-msg-content, .ai-chat-msg-box {
    padding-right: 0px;
  }
  .ai-chat-msg-box .theme-btn {
    font-size: 13px;
    padding: 9px 14px;
  }
  .water-slider.text-slider .range-slider__range {
    height: 8px;
  }
  .water-slider.text-slider .range-slider__range::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
  }
  .water-slider.text-slider .range-slider__range::-moz-range-thumb {
    width: 24px;
    height: 24px;
  }
  .min-100vh-relative-div {
    padding-bottom: 110px;
  }
  .audio-details-div {
    margin-top: 10px;
  }
  .audio-play-div {
    left: 0px;
    width: 100%;
    padding: 18px 18px 8px;
    border-radius: 20px 20px 0px 0px;
  }
  .audio-play-icons {
    margin: 0px 15px;
  }
  .play-time {
    padding: 1px 15px;
    font-size: 12px;
  }
  .play-theme-round {
    height: 45px;
    width: 45px;
    min-width: 45px;
    margin: 0px 15px;
  }
  .audio-play-icons img, .audio-play-icons svg {
    max-height: 16px;
  }
  .play-speed {
    height: 38px;
    width: 38px;
    font-size: 11px;
    line-height: 13px;
  }
}
@media screen and (max-width: 340px) {
  .sidebar-div-box{
    width: 270px;
  }
  .header-logo {
    max-width: 132px;
  }
  .header-div{
    height: 53.63px;
  }
  .res-header-dash-sticky {
    top: 53.63px;
  }
  .res-header-side-icon {
    min-width: 30px;
    width: 30px;
  }
  .nav-pills.btn-pills .nav-link {
    padding: 5px 14px;
    margin-right: 5px;
  }
  .file-action-list {
    margin-right: 0px;
  }
}